import React, { Component } from 'react';
import styled from 'styled-components';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const Form = styled.form`
  .field {
    margin-bottom: 0;
  }

  input, textarea {
    background: ${props => props.theme.colors.tan};
    color: ${props => props.theme.colors.red};
    border: 2px solid ${props => props.theme.colors.tan};
    transition: 100ms ease-in-out;
    box-shadow: none;
    padding-top: calc(0.375em + 2px);
    font-family: PostGrotesk, Arial, sans-serif;

    &:focus {
      background: ${props => props.theme.colors.tan};
      color: ${props => props.theme.colors.red};
      border: 2px solid #EBACA5;
      outline: 0;
      box-shadow: none;
    }

    &:hover {
      border: 2px solid #EBACA5;
    }

    &::placeholder {
      color: ${props => props.theme.colors.red} !important;
    }
  }

  textarea {
    height: auto;
  }

  .button {
    border: 2px solid ${props => props.theme.colors.tan};
    background: transparent;
    color: ${props => props.theme.colors.tan};
    padding-top: calc(0.375em + 2px);
    transition: 100ms ease-in-out;

    &:hover {
      background: ${props => props.theme.colors.tan};
      color: ${props => props.theme.colors.red};
    }

    .fa {
      margin-left: 1rem;
      position: relative;
      top: 2px;
      font-size: .85rem;
    }
  }
`;

const Confirmation = styled.div`
  font-size: 1rem;
  display: block;
  line-height: 1.25;
  border: 2px solid ${props => props.theme.colors.tan};
  padding-bottom: calc(0.375em - 1px);
  padding-left: 2rem;
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em);
  border-radius: .25rem;

  .fa {
    margin-left: -1.5rem;
    margin-right: .5rem;
  }
`;

const ErrorMessage = styled.span`
  font-size: .8rem;
`;

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      message: '',
      submitted: false,
      hasError: false,
      submitting: false,
      error: ''
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = event => {
    this.setState({
      submitting: true
    });
    fetch('/?no-cache=1', {
      method: 'POST',
      headers:  { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(this.handleSuccess)
      .catch(error => this.handleError(error));
    event.preventDefault();
  }

  handleSuccess = () => {
    this.setState({
      email: '',
      name: '',
      message: '',
      submitted: true,
      hasError: false,
      submitting: false,
      error: ''
    })
  }

  handleError = error => {
    console.error(error);
    this.setState({
      hasError: true,
      submitting: false,
      error: error
    });
  }

  render() {
    if (this.state.submitted) {
      return (
        <Confirmation>
          <i className="fa fa-check" aria-hidden="true"></i>
          <strong>Your message is sent!</strong> We'll get back to you as quickly as we can.</Confirmation>
      );
    } else {
      return (
        <Form
          name="contact"
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot"
          className="columns is-multiline"
        >
          <input type="hidden" name="contact" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot" onChange={this.handleInputChange} />
            </label>
          </p>
          <div className="field column is-half">
            <div className="control">
              <input
                name="name"
                type="text"
                placeholder="Your name"
                value={this.state.name}
                onChange={this.handleInputChange}
                required
                className="input"
              />
            </div>
          </div>
          <div className="field column is-half">
            <div className="control">
              <input
                name="email"
                type="email"
                placeholder="Email address"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
                className="input" 
              />
            </div>
          </div>
          <div className="field column is-full">
            <div className="control">
              <textarea
                name="message"
                type="textarea"
                placeholder="Your message"
                value={this.state.message}
                onChange={this.handleInputChange}
                required
                className="input"
                rows="8">
              </textarea>
            </div>
          </div>
          <div className="field column is-full">
            <div className="control">
              <button className="button" type="submit">{this.state.submitting ? 'Sending message' : 'Send message'} <i className="fa fa-arrow-right"></i></button>
            </div>
          </div>
          {this.state.hasError && <div className="column">
            <ErrorMessage>
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Something went wrong. Please try again.
            </ErrorMessage>
          </div>}
        </Form>
      );
    }
  }
}

export default ContactForm;
