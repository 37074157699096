import React from 'react';
import config from '../utils/siteConfig';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';

const Contact = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const ContactPage = () => {
  const postNode = {
    title: `${config.siteTitle} | Contact`,
  };

  return(
    <Layout>
      <Helmet>
        <title>{`${config.siteTitle} | Contact`}</title>
      </Helmet>
      <SEO pagePath="contact" postNode={postNode} customTitle />

      <section className="section reveal">
        <Contact className="container">
          <div className="columns">
            <div className="column is-one-fifth is-hidden-mobile"></div>
            <div className="column is-three-fifths is-full-mobile">
              <h1>Say hello.</h1>
              <p>Use this nifty form, or email us at <a href="mailto:hello@caribou.co">hello@caribou.co</a>.</p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-fifth is-hidden-mobile"></div>
            <div className="column is-three-fifths">
              <ContactForm />
            </div>
            <div className="column is-one-fifth">
              <h3>Have a project in mind?</h3>
              <p>These things will help us out:</p>
              <ul>
                <li>Who you are</li>
                <li>Your idea</li>
                <li>Your timeline</li>
                <li>Where you heard about us</li>
              </ul>
            </div>
          </div>
        </Contact>
      </section>
    </Layout>
  );
};

export default ContactPage;
